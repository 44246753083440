.login {
  word-wrap: break-word;
}

.right {
  text-align: right;
}

.version {
  font-size: 8pt;
  color: #CCCCCC;
  font-weight: 100;
}

.login-row {
  justify-content: center;
}

.btn-block {
  display: block;
  width: 100%;
}

.login-alert {
  margin-top: 10px;
}

.login-change-password {
  background-color: #4caf50 !important;
}