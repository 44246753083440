body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
}

button {
  border-radius: 7px !important;
}

.butAction {
  margin-left: 2%;
  padding: 0%;
  padding-right: 10px;
}

.butActionInUse {
  margin-left: 2%;
  padding: 0%;
  background-color: #fff;
  color: black;
  padding-right: 10px;
}

.xpto {
  font-size: 25px;
}

.txtAction {
  margin-left: 5%;
  text-transform: none !important;
}

#toast-container>div {
  opacity: 1;
}

.content-stretched {
  margin-top: 50px;
  min-height: calc(100% - 123px);
}

.content-dash {
  margin-top: 50px;
  min-height: calc(100% - 123px);
  padding-left: 25px;
  padding-right: 25px;
}

.nav {
  flex-direction: column;
}

.guardian-menu-icon {
  float: none;
}

.guardian-menu-item {
  padding-bottom: 0px !important;
}

.guardian-menu-anchor {
  text-decoration: none;
  font-size: 14px;
  line-height: 30px;
  display: flex;
}

.guardian-menu-anchor-closed {
  width: 30px;
  margin-right: 0px !important;
  text-align: center;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  margin-bottom: 5px;
}

.link-button .material-icons {
  vertical-align: middle;
}

.link-button:hover,
.link-button:focus {
  text-decoration: underline;
}

.grid-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
}

.grid-button .material-icons {
  vertical-align: middle;
}

.grid-button:hover,
.grid-button:focus {
  text-decoration: underline;
}

.navbar .navbar-nav>li>button {
  color: inherit;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

.forcedLink {
  text-decoration: none;
}