#navHeader {
  padding: 0; 
}

.params {
  cursor: pointer;
}

.green {
  color: #4caf50;
}

.guardian-header {
  margin-top: 15px;;
  display: block!important;
  height: auto!important;
  padding-bottom: 0;
  overflow: visible!important;
}

.guardian-header-right {
  position:absolute!important;
  right: 0;
}

.guardian-header-left {
  float: left;
}

.guardian-header-btn {
  padding-right: 10px !important;
  color: #555;
  text-decoration: none;
}

.relative{
  position:relative!important;
}

.grid-parent {
  display: grid;
  grid-auto-columns: minmax(130px, auto);
  width: fit-content!important;
  position:absolute;
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.box2 {
  grid-column-start: 2;
  grid-column-end: 3;
}

.box3 {
  grid-column-start: 3;
  grid-column-end: 4;
}